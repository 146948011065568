import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { ListGroup, Container, ButtonGroup, Dropdown, Col, Row, Form, Button, Alert, Modal, Accordion, Card } from 'react-bootstrap';
import auth from '../auth/auth';
import Constants from "../constants";
import ReCAPTCHA from "react-google-recaptcha";

import QRCode from "react-qr-code";

import {Helmet} from "react-helmet";

import decodeJwt from 'jwt-decode';
import { getIPRange } from 'get-ip-range';

const API_ENDPOINT = Constants.API_ENDPOINT;

export const AgentSearch = ({props, reloadSearch }) => {
  // History hook
  const history = useHistory();

  var hostListRaw = [];

/*
  React.useEffect(() => {
    childFunc.current = searchReload
  }, []);
*/

//host_nameHostData

  const [host_nameHostData, setHost_nameHostData] = useState('');
  const [network, setNetwork] = useState('');
  const [hostList, setHostList] = useState([]);
  const [hostListView, setHostListView] = useState([]);
  const [unavailableHostListView, setUnavailableHostListView] = useState([]);
  const [error, setError] = useState('');
  const [outMessage, setOutMessage] = useState('');
  const [stdoutMessage, setStdoutMessage] = useState('');
  const [stderrMessage, setStderrMessage] = useState('');
  const [stdoutMessageLogs, setStdoutMessageLogs] = useState('');
  const [stderrMessageLogs, setStderrMessageLogs] = useState('');
  const [show, setShow] = useState(false);
  const [showLogs, setShowLogs] = useState(false);
  const [showHostData, setShowHostData] = useState(false);
  const [actionListView, setActionListView] = useState([]);
  const [actionListFilterView, setActionListFilterView] = useState([]);
  const [proxyListView, setProxyListView] = useState([]);
  const [tariffsListView, setTariffsListView] = useState([]);
  const [hostDataView, setHostDataView] = useState([]);
  const [tariffsDataView, setTariffsDataView] = useState([]);
  const [showTariffsData, setShowTariffsData] = useState(false);


  const [showCreateAction, setShowCreateAction] = useState(false);

  const [createCustomProxyHost, setCreateCustomProxyHost] = useState('');
  const [createCustomProxyIp, setCreateCustomProxyIp] = useState('');
  const [createCustomProxyPortMin, setCreateCustomProxyPortMin] = useState('');
  const [createCustomProxyPortMax, setCreateCustomProxyPortMax] = useState('');
  const [dependenceForAdd, setDependenceForAdd] = useState('');
  const [tagForAdd, setTagForAdd] = useState('');
  const [createCustomActionName, setCreateCustomActionName] = useState('');

  // const [customActionView, setCustomActionView] = useState([]);
  const [customActionView, setCustomActionView] = useState({});



  const [customActionListView, setCustomActionListView] = useState([]);
  const [actionDataView, setActionDataView] = useState([]);



  const [searchActionItem, setSearchActionItem] = useState('');


  //Custom action init data
  const [customAction, setCustomAction] = useState({
   "name" : "",
    "comment" : "",
    "dependencies" : ["test"],
    "conditions" : {
        "CPU" : "None",
        "MEM" : "None",
        "DISK" : "None"
    },
    "tags" : ["test"],
    "source" : "https://",
    "auth_type" : "None",
    "source_credentials" : "",
    "branch" : "",
    "source_path" : "",
    "source_run_file" : "run.sh",
    "action_timeout" : "300",
    "source_timeout" : "300",
    "environment_variables" : [],
    "ports" : []  ,
    "action_type": "private"
  });

  const [environment_variablesForAdd, setEnvironment_variablesForAdd] = useState({
    "name":"",
    "comment":"",
    "value":""
  });

  const [portsForAdd, setPortsForAdd] = useState({
    "name":"",
    "value":"",
    "port_type":"link",
    "vm_id":"",
    "comment":""
  });



  const handleClose = (host_id,ip,i) => setShow(false);
  const handleCloseLogs = (host_id,ip,i) => setShowLogs(false);
  const handleCloseHostData = (host_id,ip,i) => setShowHostData(false);
  const handleCloseTariffsData = (host_id,ip,i) => setShowTariffsData(false);
  const handleCloseCreateAction = () => setShowCreateAction(false);


  const handleSearchInputChange = (e) => { 
    const searchTerm = e.target.value;
    setSearchActionItem(searchTerm);

    const filteredItems = actionListView.filter((customActionNext) =>
    customActionNext.comment.toLowerCase().includes(searchTerm.toLowerCase()) || customActionNext.name.toLowerCase().includes(searchTerm.toLowerCase())
    ).slice(0,10);

    // error when filter, slice maximum poka

    setActionListFilterView(filteredItems);


  };



  const handleShowTariffsData = async () => {
    var default_proxy_addr = "";

    console.log("handle show tariffs");
    const token = localStorage.getItem('token');
    const request_tariffs = new Request(`${API_ENDPOINT}/listtariffs`, {
    method: 'GET',
    headers: {'Authorization': `Bearer ${token}`}
    });
    console.log(request_tariffs);
    const response_tariffs = await fetch(request_tariffs);
    console.log(response_tariffs);
    const data_tariffs = await response_tariffs.json();
    const nextTariffsListView = data_tariffs.map((p, i) => {
      if (i === 0) {
        return p;      
        } else {
        //p.proxy_addr = data_in.proxy_addr;
        //p.proxy_ext_addr = data_in.proxy_ext_addr;
        //p.proxy_ext_port = data_in.proxy_ext_port;
        //default_proxy_addr = "";
        return p;      
       }
    });
    console.log("handle show tariffslist view");
    console.log(nextTariffsListView);
    setTariffsListView(nextTariffsListView);

    setShowTariffsData(true);    

    }



  const handleShowHostData = async (data_in, i) => {
    var default_proxy_addr = "";

    console.log("handleShowHostData");

    var data = [];
    data[0] = data_in;
    await setHostDataView(data);
    await setHostDataView(data);

    console.log("data_in");
    console.log(data_in);
    console.log("data");
    console.log(data);

    console.log("hostDataView");
    console.log(hostDataView);

    
    //const nextHostDataView = hostDataView.map((c, i) => {
    const nextHostDataView = data.map((c, i) => {
        c.status = c.status;
        c.bind_link_url = API_ENDPOINT + "/back/qr-host/?host_id=" + encodeURIComponent(c.host_id);
        //c.bind_link_url = API_ENDPOINT + "/qr-host/host_id=" + encodeURIComponent(c.host_id);
        //c.bind_link_url = API_ENDPOINT + "/proxy/" + c.proxy_addr+ "/" + c.proxy_ext_addr + "/" + c.proxy_ext_port + "/agent/bind";


        if (c.ports) {
            console.log("in port cycle");
            console.log(c);
            c.ports.map((new_c,ii) => {
                 //new_c = data_in
                 if (new_c.type_port == "link"){
                     new_c.link_url = "https://" + new_c.proxy_addr + "/proxy/" + new_c.proxy_addr + "/" + new_c.proxy_ext_addr + "/" + new_c.proxy_ext_port + "/index.html";
                     new_c.link_view = "https://" + new_c.proxy_addr + "/proxy/" + new_c.proxy_addr + "/" + new_c.proxy_ext_addr + "/" + new_c.proxy_ext_port + "/index.html";
                     //new_c.link_url = "https://dev.cloudlocalnet.com/proxy/" + new_c.proxy_addr + "/" + new_c.proxy_ext_addr + "/" + new_c.proxy_ext_port + "/";
                     //new_c.link_view = "https://dev.cloudlocalnet.com/proxy/" + new_c.proxy_addr + "/" + new_c.proxy_ext_addr + "/" + new_c.proxy_ext_port + "/";
                      }
                 if (new_c.type_port == "port"){
                     new_c.link_url = "http://" + new_c.proxy_ext_addr + ":" + new_c.proxy_ext_port;
                     new_c.link_view = new_c.proxy_ext_addr + ":" + new_c.proxy_ext_port;
                      }


                 new_c.view = new_c.name + " " + new_c.value + " " + new_c.type_port + " ( " + new_c.proxy_addr + " ) " ;
                 return new_c;
                 console.log(new_c);
            });
          }
        return c;      
    });
    console.log("nextHostDataView");
    console.log(nextHostDataView);

    //setHostDataView(nextHostDataView);
    console.log(nextHostDataView);
    console.log("handle show host data view");

    setHost_nameHostData(data_in.host_name);

    setShowHostData(true);    

}



  const handleShow = async (data_in,i,action_type) => {
    console.log("handle show");
    console.log(data_in);
    const token = localStorage.getItem('token');
    const request_proxy = new Request(`${API_ENDPOINT}/listproxy`, {
    method: 'GET',
    headers: {'Authorization': `Bearer ${token}`}
    });
    console.log(request_proxy);
    const response_proxy = await fetch(request_proxy);
    console.log(response_proxy);
    const data_proxy = await response_proxy.json();
    var default_proxy_addr = "";
    const nextProxyListView = data_proxy.map((p, i) => {
      if (i === 0) {
        default_proxy_addr = p.host;
        return p;      
        } else {
        //p.proxy_addr = data_in.proxy_addr;
        //p.proxy_ext_addr = data_in.proxy_ext_addr;
        //p.proxy_ext_port = data_in.proxy_ext_port;
        //default_proxy_addr = "";
        return p;      
       }
    });
    console.log("handle show proxy list view");
    console.log(nextProxyListView);
    setProxyListView(nextProxyListView);
    //const default_proxy_addr = data_proxy.[0].host;
    const request = new Request(`${API_ENDPOINT}/actionslist?action_type_query=${action_type}`, {
    method: 'GET',
    headers: {'Authorization': `Bearer ${token}`}
    });
    const response = await fetch(request);
    const data = await response.json();
    const nextActionListView = data.map((c, i) => {
        c.proxy_addr = data_in.proxy_addr;
        c.proxy_ext_addr = data_in.proxy_ext_addr;
        c.proxy_ext_port = data_in.proxy_ext_port;
        if (c.ports) {
            c.ports.map((new_c,ii) => {
                 new_c.proxy_addr = default_proxy_addr;
                 return new_c;
            });
          }

        return c;      
    });
    console.log("handle show actions list view");
    console.log(actionListView);
    console.log(nextActionListView);
    setActionListView(nextActionListView);
    setActionListFilterView(nextActionListView.slice(0,10));
    setSearchActionItem("");

    setShow(true);    
   };



  const handleShowActionsLogs = async (data_in,i) => {
    console.log("handle show actions logs");
    console.log(data_in);

    setStdoutMessageLogs()
    setStderrMessageLogs()

    const token = localStorage.getItem('token');
    const request = new Request(`${API_ENDPOINT}/proxy/${data_in.proxy_addr}/${data_in.proxy_ext_addr}/${data_in.proxy_ext_port}/agent/get_actions_logs`, {
    method: 'GET',
    headers: {'Content-Type': 'text/html','Authorization': `Bearer ${token}`}
    });
    const response = await fetch(request);
    const data = await response.json();


    // setOutMessage("Action: id:"+ id + " result:" + data.full_output);
    console.log(data);
    setStdoutMessageLogs(data.full_stdout)
    setStderrMessageLogs(data.full_stderr)

    setShowLogs(true);
   };







  // Function to Bind Host
  const addHostBind = async (data_in, index) => {
    const token = localStorage.getItem('token');
    console.log(data_in);
    const request = new Request(`${API_ENDPOINT}/proxy/${data_in.proxy_addr}/${data_in.proxy_ext_addr}/${data_in.proxy_ext_port}/agent/bind`, {
    method: 'GET',
    headers: {'Content-Type': 'text/html','Authorization': `Bearer ${token}`}
    });
    const response = await fetch(request);
    const data = await response.json();

    setOutMessage("Bind:  result:" + data.message);
    console.log(data.message);
    if (data.message=="OK") {
    const nextHostListView = hostListView.map((c, i) => {
      if (i === index) {
        c.btnColor="warning";
        return c;
      } else {
        return c;
      }
    });
    setHostListView(nextHostListView);
    }
  };

  // Function to Bind Host
  const deleteHostFromBase = async (host_id) => {
    console.log(host_id);

    const query_data={
            "host_id":host_id, 
            };
    const token = localStorage.getItem('token');
    console.log("QUERY")
    console.log(query_data)
    const request = new Request(`${API_ENDPOINT}/back/host-delete-from-base`, {
    method: 'POST',
    cache: "no-cache",
    body: JSON.stringify(query_data),
     headers: {
              "Content-Type": "application/json", 
                 'Authorization': `Bearer ${token}`,
             },
    });
    const response = await fetch(request);
    const data = await response.json();

    searchReload();


  };


  // Function for start action on host
  const startAction = async (data_in, id) => {
    setStdoutMessage()
    setStderrMessage()
    console.log(data_in)
    console.log("start post action")
    const query_data={
            "action_id":data_in.id, 
            "environment_variables":data_in.environment_variables,
            "ports":data_in.ports,
            };
    const token = localStorage.getItem('token');
    console.log("QUERY")
    console.log(query_data)
    const request = new Request(`${API_ENDPOINT}/proxy/${data_in.proxy_addr}/${data_in.proxy_ext_addr}/${data_in.proxy_ext_port}/agent/action`, {
    method: 'POST',
    cache: "no-cache",
    body: JSON.stringify(query_data),
     headers: {
              "Content-Type": "application/json", 
                 'Authorization': `Bearer ${token}`,
             },
    });
    const response = await fetch(request);
    const data = await response.json();
    setOutMessage("Action: id:"+ id + " result:" + data.full_output);
    console.log(data);
    setStdoutMessage(data.full_stdout)
    setStderrMessage(data.full_stderr)
  };



  // Function for start action on host
  const deleteAction = async (data_in, id) => {
    setStdoutMessage()
    setStderrMessage()
    console.log(data_in)
    console.log("start delete action")
    const query_data={
            "action_id":data_in.id, 
            };
    const token = localStorage.getItem('token');
    console.log("QUERY")
    console.log(query_data)
    const request = new Request(`${API_ENDPOINT}/back/action-custom-del`, {
    method: 'POST',
    cache: "no-cache",
    body: JSON.stringify(query_data),
     headers: {
              "Content-Type": "application/json", 
                 'Authorization': `Bearer ${token}`,
             },
    });
    const response = await fetch(request);
    const data = await response.json();
    setOutMessage("Action: id:"+ id + " result:" + data.full_output);
    console.log(data);
    setStdoutMessage(data.full_stdout)
    setStderrMessage(data.full_stderr)
    setShow(false);

  };




  // Function for apply tariff to user
  const applyTariff = async (data_in, id) => {
    console.log(data_in)
    console.log("start apply Tariff")
    // Add on test payment_id
    const query_data={
            "plan_id":data_in.id, 
            "user_id":"test",
            "payment_id":"64283be2de4b1fc68813fc15",
            };
    const token = localStorage.getItem('token');
    console.log("QUERY")
    console.log(query_data)
    const request = new Request(`${API_ENDPOINT}/test-add-plan`, {
    method: 'POST',
    cache: "no-cache",
    body: JSON.stringify(query_data),
     headers: {
              "Content-Type": "application/json", 
                 'Authorization': `Bearer ${token}`,
             },
    });
    const response = await fetch(request);
    const data = await response.json();
    setOutMessage("Action: id:"+ id + " result:" + data.full_output);
    console.log(data);
  };





  // Function to Bind Host
  const addHostUnBind = async (data_in, index) => {
    const token = localStorage.getItem('token');
    const request = new Request(`${API_ENDPOINT}/proxy/${data_in.proxy_addr}/${data_in.proxy_ext_addr}/${data_in.proxy_ext_port}/agent/unbind`, {
    method: 'GET',
    headers: {'Content-Type': 'text/html','Authorization': `Bearer ${token}`}
    });
    const response = await fetch(request);
    const data = await response.json();
    setOutMessage("UnBind: result:" + data.message);
    //console.log(data_in.host_id);
    console.log(data.message);

    if (data.message=="OK") {
    const nextHostListView = hostListView.map((c, i) => {
      if (i === index) {
        c.btnColor="success";
        return c;
      } else {
        return c;
      }
    });
    setHostListView(nextHostListView);
    }
  };

  // Function to change enviroment variable
  const setEnv = async (data_in, index_i, index_j, value_change) => {
    //const nextActionListView = actionListView.map((c, i) => {
    const nextActionListView = actionListFilterView.map((c, i) => {
      if (i === index_i) {
         console.log(c);
         console.log(index_i);
         console.log(index_j);
         console.log(value_change);
        c.environment_variables[index_j].value=value_change;
        return c;
      } else {
        return c;
      }
    });
    setActionListFilterView(nextActionListView);
    //setActionListView(nextActionListView);
   //console.log(nextActionListView)
  };

  // Function to change enviroment variable
  const setEnvPorts = async (data_in, index_i, index_j, value_change) => {
    // const nextActionListView = actionListView.map((c, i) => {
    const nextActionListView = actionListFilterView.map((c, i) => {
      if (i === index_i) {
        c.ports[index_j].proxy_addr=value_change;
        return c;
      } else {
        return c;
      }
    });
     console.log(data_in, index_i, index_j, value_change);
    // setActionListView(nextActionListView);
    setActionListFilterView(nextActionListView);
   console.log(nextActionListView)
  };

  // Function to call submit
  const callSubmit = async (e) => {
    // Prevents page reload on wrongs creds
    if (e){
        e.preventDefault();
        console.log(e);
      };
    setError('');
    const token = localStorage.getItem('token');
    try {
     //search
    const request = new Request(`${API_ENDPOINT}/back/search-hosts`, {
    method: 'GET',
    headers: {'Content-Type': 'text/html','Authorization': `Bearer ${token}`}
    });
    const response = await fetch(request);
    const data = await response.json();
    console.log(data)
    const hostListView = data.map((c, i) => {
        c.btnColor="success";
        c.spltColor="danger"
        if (c.authorized_user) {
           c.btnColor="warning";
            }
        if (c.status=="open_session") {
           c.spltColor="success";
            }
        return c;
         });
    setHostListView(hostListView);
    }
     catch (err) {
      if (err instanceof Error) {
        // Handle errors thrown from frontend
        setError(err.message);
      } 
      else {
        // Handle errors thrown from backend
        if (err === 'REGISTER_USER_ALREADY_EXISTS') {
          setError('Email ID is already registered. Please use your credentials to login.');
        }
        else {
          setError('Error occured in the API.');
        }
      }
    }
  };

  // Function to call submit
  const searchReload = async () => {
    // Prevents page reload on wrongs creds
    const token = localStorage.getItem('token');
    try {
     //search
    const request = new Request(`${API_ENDPOINT}/back/search-hosts`, {
    method: 'GET',
    headers: {'Content-Type': 'text/html','Authorization': `Bearer ${token}`}
    });
    const response = await fetch(request);
    const data = await response.json();
    console.log(data)
    const hostListView = data.map((c, i) => {
        c.btnColor="success";
        c.spltColor="danger"
        if (c.authorized_user) {
           c.btnColor="warning";
            }
        if (c.status=="open_session") {
           c.spltColor="success";
            }
        return c;
         });
    setHostListView(hostListView);
    }
     catch (err) {
      if (err instanceof Error) {
        // Handle errors thrown from frontend
        setError(err.message);
      } 
      else {
        // Handle errors thrown from backend
        if (err === 'REGISTER_USER_ALREADY_EXISTS') {
          setError('Email ID is already registered. Please use your credentials to login.');
        }
        else {
          setError('Error occured in the API.');
        }
      }
    }
  };


  // Function to call submit
  const searchUnavailable = async (e) => {
    // Prevents page reload on wrongs creds
    console.log("search Unavailable");
    if (e){
        e.preventDefault();
        console.log(e);
      };
    setError('');
    const token = localStorage.getItem('token');
    try {
     //search
    const request = new Request(`${API_ENDPOINT}/back/search-hosts-unavailable`, {
    method: 'GET',
    headers: {'Content-Type': 'text/html','Authorization': `Bearer ${token}`}
    });
    const response = await fetch(request);
    const data = await response.json();
    console.log(data)
    if (data.message) { 
            setError(data.message)
         } else {


    const unavailableHostListView = data.map((c, i) => {
        c.btnColor="success";
        c.spltColor="danger"
        if (c.authorized_user) {
           c.btnColor="warning";
            }
        if (c.status=="open_session") {
           c.spltColor="success";
            }
        return c;
         });
    setUnavailableHostListView(unavailableHostListView); 
     }
    }
     catch (err) {
      if (err instanceof Error) {
        // Handle errors thrown from frontend
        setError(err.message);
      } 
      else {
        // Handle errors thrown from backend
        if (err === 'REGISTER_USER_ALREADY_EXISTS') {
          setError('Email ID is already registered. Please use your credentials to login.');
        }
        else {
          setError('Error occured in the API.');
        }
      }
    }
  };









//CustomActions

  const handleShowCreateAction = async (user_data) => {
    setShow(false);

    console.log("handle show create action window");
    console.log(user_data);
    console.log("customAction");
    console.log(customAction);
    user_data.action_id = user_data.id;
    console.log(user_data);
    const token = localStorage.getItem('token');

    const query_data={
            "action":"",
            "action_id": user_data.id,
            "group_id":"",
            };
    console.log(query_data);
    var data_new = {};
    console.log("get custom action");
    console.log(query_data);
    const request_get = new Request(`${API_ENDPOINT}/back/get-custom-action`, {
    method: 'POST',
    cache: "no-cache",
    body: JSON.stringify(query_data),
     headers: {
              "Content-Type": "application/json",
                 'Authorization': `Bearer ${token}`,
             },
    });
    const response_get = await fetch(request_get);
    const data_get = await response_get.json();

    console.log("Data Get");
    console.log(data_get);


    data_get[0].action_id = data_get[0].id;

    const nextCustomActionListView = data_get.map((c, i) => {
        var index_arr = 0;
        for(const element of c.members) {
             if (element.group_role == "admin")
                {
                  c.members[index_arr].btnAdminColor = "primary";
                } else {
                  c.members[index_arr].btnAdminColor = "secondary";
                }
             index_arr += 1;
         };

        c.btnColor="success";
        c.spltColor="danger"
        if (c.authorized_user) {
           c.btnColor="warning";
            }
        if (c.status=="active") {
        // if (c.status=="open_session") {
           c.spltColor="success";
            }
      //if (c.host == proxy_name) {
        data_new = c;
        return c;
      //} else {
        //return c;
      //}
    });
        //await setActionDataView(data_new);
        //await setCustomActionListView(nextCustomActionListView);

        setCustomAction(nextCustomActionListView[0]);
         
        console.log(nextCustomActionListView[0]);
        console.log("customAction");
        console.log(customAction);

    //setCustomAction(user_data);
    setShowCreateAction(true);
    }

  const handleShowCopyAction = async (user_data) => {
    setShow(false);

    console.log("handle show create action window");
    console.log(user_data);
    //user_data.action_id = user_data.id;
    console.log(user_data);
    const token = localStorage.getItem('token');
    setCustomAction(user_data);
    setShowCreateAction(true);
    }


  // Function for create custom action
  const createCustomAction = async () => {
    console.log(customAction)
    console.log("start create custom action")
    

    /*const query_data={
            "name":createCustomActionName,
            };*/
    const query_data={
            "name":createCustomActionName,
            };
    const token = localStorage.getItem('token');
    console.log("QUERY");
    console.log(customAction);
    const request = new Request(`${API_ENDPOINT}/back/action-custom-add`, {
    method: 'POST',
    cache: "no-cache",
    body: JSON.stringify(customAction),
     headers: {
              "Content-Type": "application/json",
                 'Authorization': `Bearer ${token}`,
             },
    });
    const response = await fetch(request);
    const data = await response.json();
    console.log(data);
    setOutMessage(data.message);
  };


/*
 const delFromCustomActionArray = async (name, id) => {
    //console.log(group_id)
    console.log("del from array");
    console.log(name, id);

    await setCustomAction({
  ...customAction, // Copy other fields
  conditions: { // but replace the artwork
     ...customAction.conditions, // with the same one
    [id]:name
     }
  });

};
*/

// del element from array in customAction
 const delFromCustomActionArray = async (changed_array, changed_value, old_action) => {
    //console.log(group_id)
    //const new_deps = await customAction.dependencies.filter(a => a.id !== changed_value );
    var new_deps = "";
    var deleted_deps = "";

    if (changed_array == "environment_variables" ) {
        new_deps = await customAction[changed_array].filter(a => {return a.name !== changed_value} );
        deleted_deps = await customAction[changed_array].filter(a => {return a.name === changed_value} );
        await setEnvironment_variablesForAdd(deleted_deps[0]);
      } else {
        new_deps = await customAction[changed_array].filter(a => {return a !== changed_value} );
        deleted_deps = await customAction[changed_array].filter(a => {return a === changed_value} );
     }
    const newCustomAction = {
      ...customAction, // Copy other fields
      [changed_array]:new_deps
  };
    await setCustomAction(newCustomAction);

};


// del element from array in customAction
 const delFromCustomActionArrayPort = async (changed_array, changed_value_value, changed_value_port_type, changed_value_vm_id, old_action) => {
    //console.log(group_id)
    //const new_deps = await customAction.dependencies.filter(a => a.id !== changed_value );

    console.log(changed_array, changed_value_value, changed_value_port_type, changed_value_vm_id, old_action);

    console.log(customAction[changed_array]);

    const new_deps = await customAction[changed_array].filter(a => {return a.value !== changed_value_value || a.port_type !== changed_value_port_type || a.vm_id !== changed_value_vm_id} );
    const deleted_deps = await customAction[changed_array].filter(a => {return a.value === changed_value_value && a.port_type === changed_value_port_type && a.vm_id === changed_value_vm_id} );

    console.log("new_deps");
    console.log(new_deps);
    console.log("deleted_deps");
    console.log(deleted_deps);

    //new state
    const newCustomAction = {
      ...customAction, // Copy other fields
      [changed_array]:new_deps
  };
    await setCustomAction(newCustomAction);

    await setPortsForAdd(deleted_deps[0]);


};


  //function update customAction
  const changeCreateCustomAction = async (variable_for_change, value) => {
    console.log("before");
    console.log(variable_for_change, value, customAction);
    await setCustomAction({
  ...customAction, // Copy other fields
    [variable_for_change]:value
  });
    console.log("after");
    console.log(variable_for_change, value, customAction);    
  };


  //changeEnvironment_variablesForAdd
  //function update customAction
  const changeEnvironment_variablesForAdd = async (variable_for_change, value) => {
    console.log("before");
    console.log(variable_for_change, value, environment_variablesForAdd);
    if (variable_for_change=="all") {
    await setEnvironment_variablesForAdd({
    "name":"",
    "comment":"",
    "value":""
  });

}
    else {
    await setEnvironment_variablesForAdd({
  ...environment_variablesForAdd, // Copy other fields
    [variable_for_change]:value
  });

   }
    console.log("after");
    console.log(variable_for_change, value, environment_variablesForAdd);
  };

  //changePortsForAdd
  const changePortsForAdd = async (variable_for_change, value) => {
    console.log("before");
    console.log(variable_for_change, value, portsForAdd);
    if (variable_for_change=="all") {
    await setPortsForAdd({
    "name":"",
    "value":"",
    "port_type":"link",
    "vm_id":"",
    "comment":""
  });

   } else {
    await setPortsForAdd({
  ...portsForAdd, // Copy other fields
    [variable_for_change]:value
  });

   }
    console.log("after");
    console.log(variable_for_change, value, portsForAdd);
  };


  //function update customAction.conditions
  const changeCreateCustomActionConditions = async (variable_for_change, value) => {
    console.log("before");
    console.log(variable_for_change, value, customAction);
    await setCustomAction({
  ...customAction, // Copy other fields
  conditions: { // but replace the
     ...customAction.conditions, // with the same one
    [variable_for_change]:value
     }
  });
        console.log("after");
    await console.log(variable_for_change, value, customAction);
  };







  //function update customAction.conditions
  const addToCustomActionArray = async (changed_array, variable_for_add) => {
    console.log("before");
    console.log(changed_array, variable_for_add);

    await setCustomAction({
     ...customAction, // Copy other fields
     [changed_array]: [
        ...customAction[changed_array],
        variable_for_add 
     ]

     /*
      conditions: { // but replace the 
     ...customAction.conditions, // with the same one
    [variable_for_change]:value
     }
     */
  });
        console.log("after");
    await console.log(changed_array, variable_for_add, customAction);
  };



  // Function for del member from proxy
  const changeMemberFromAction = async (group_id, action, action_id) => {
    console.log("change member action");
    console.log(group_id);
    console.log(action);
    console.log(action_id);
  
    const query_data={
            "action":action,
            "action_id": action_id,
            "group_id":group_id,
            };

    console.log(query_data);
    const token = localStorage.getItem('token');
    console.log("QUERY")
    console.log(query_data)
    const request = new Request(`${API_ENDPOINT}/back/change-member-status-custom-action`, {
    method: 'POST',
    cache: "no-cache",
    body: JSON.stringify(query_data),
     headers: {
              "Content-Type": "application/json",
                 'Authorization': `Bearer ${token}`,
             },
    });
    const response = await fetch(request);
    const data = await response.json();
    console.log(data);

    //await searchReload();

    var data_new = {};
    console.log("get custom action");
    console.log(query_data);
    const request_get = new Request(`${API_ENDPOINT}/back/get-custom-action`, {
    method: 'POST',
    cache: "no-cache",
    body: JSON.stringify(query_data),
     headers: {
              "Content-Type": "application/json",
                 'Authorization': `Bearer ${token}`,
             },
    });
    const response_get = await fetch(request_get);
    const data_get = await response_get.json();

    console.log(data_get);

    const nextCustomActionListView = data_get.map((c, i) => {
        var index_arr = 0;
        for(const element of c.members) {
             if (element.group_role == "admin")
                {
                  c.members[index_arr].btnAdminColor = "primary";
                } else {
                  c.members[index_arr].btnAdminColor = "secondary";
                }
             index_arr += 1;
         };

        c.btnColor="success";
        c.spltColor="danger"
        if (c.authorized_user) {
           c.btnColor="warning";
            }
        if (c.status=="active") {
        // if (c.status=="open_session") {
           c.spltColor="success";
            }
      //if (c.host == proxy_name) {
        data_new = c;
        return c;
      //} else {
        //return c;
      //}
    });
        //await setActionDataView(data_new);
        //await setCustomActionListView(nextCustomActionListView);

        await setCustomAction(nextCustomActionListView[0]);



  };





  // Fetch user information on page load
  useEffect(() => {
    const fetchData = async (e) => {

    callSubmit(e);

    };
    fetchData();


    if (reloadSearch) {
      searchReload();
    }

  // eslint-disable-next-line
  }, [reloadSearch]);


  return (
    <>



      <h2>Agent Find in LocalNet</h2>



      <Container className="mt-4">
                   <Row>
                       {hostListView.map((data,i) =>
                 <Col className="mt-4" key={i}> 
	        <Dropdown as={ButtonGroup} >
	          <Button variant={data.btnColor} id="button-for-host" onClick={(e) => handleShowHostData(data,i)}> {data.host_name}  </Button>
	          <Dropdown.Toggle split variant={data.spltColor} id="dropdown-split-basic" />	    
	          <Dropdown.Menu>
	            <Dropdown.Item href="#/action-bind" onClick={(e) => handleShowActionsLogs(data,i)}>Actions Logs</Dropdown.Item>
	            <Dropdown.Item href="#/action-bind" onClick={(e) => addHostBind(data,i)}>Bind</Dropdown.Item>
	            <Dropdown.Item href="#/action-unbind" onClick={(e) => addHostUnBind(data,i)}>UnBind</Dropdown.Item>
	            <Dropdown.Item href="#/action-action" onClick={(e) => handleShow(data, i, "public")}>Public Action</Dropdown.Item>
	            <Dropdown.Item href="#/action-action-private" onClick={(e) => handleShow(data, i, "private")}>Private Action</Dropdown.Item>
                    <Dropdown.Divider />
	            <Dropdown.Item href="#/action-delete" onClick={(e) => deleteHostFromBase(data.host_id)}>Delete from base</Dropdown.Item>
	          </Dropdown.Menu>
	        </Dropdown> 
                    </Col>
                        )}
                  </Row>


                   <Row>
                       {unavailableHostListView && unavailableHostListView.map((data_un,i) =>
                      <Col className="mt-4" key={i}> 
                 <Row>
                            <Button variant={data_un.btnColor}>{data_un.host_name}:{data_un.owner}</Button>{' '}

                     </Row>
                    </Col>
                        )}
                  </Row>




      <Modal show={showTariffsData} onHide={handleCloseTariffsData} >
        <Modal.Header closeButton>
          <Modal.Title>Add tariff to user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
    <Accordion defaultActiveKey="0">
                   {tariffsListView.map((data,jk) =>
        <Card key={jk+1}>
           <Accordion.Toggle as={Card.Header} eventKey={jk+1}>
               {data.name} 
           </Accordion.Toggle>
           <Accordion.Collapse eventKey={jk+1}>
           <Card.Body>
             <Form> <Row> {data.comment}   </Row>

          <Button variant="secondary" size="sm" onClick={(e) => applyTariff(data)}>
            Apply
          </Button>
             </Form>
           </Card.Body>
           </Accordion.Collapse>
        </Card>
                        )}
          </Accordion>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>




      <Modal show={showHostData} onHide={handleCloseHostData} >
        <Modal.Header closeButton>
          <Modal.Title>Host {host_nameHostData}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           Host Data
                   {hostDataView.map((data_host,ll) =>
        <Card key={ll+1}>
           <Card.Body>
                      <Card.Title>Status: {data_host.status} </Card.Title>
                      <Card.Title>Remote_IP: {data_host.agent_remote_ip} </Card.Title>
                      <Card.Title> Host Link for Bind: </Card.Title>
                            <Card.Link href={data_host.bind_link_url}>Host_Link </Card.Link>
                    <QRCode size={256} style={{ height: "auto", maxWidth: "50%", width: "50%" }} value={data_host.bind_link_url} viewBox={`0 0 256 256`} level='L' />
                      

           </Card.Body>
                      {data_host.ports && data_host.ports.map( (k_host_port, ind_host_port) => (
           <Card.Body>
                      <Card.Title> {k_host_port.view} </Card.Title>
                            <Card.Link href={k_host_port.link_url}>{k_host_port.link_view} </Card.Link>
                    <QRCode size={256} style={{ height: "auto", maxWidth: "50%", width: "50%" }} value={k_host_port.link_url} viewBox={`0 0 256 256`} level='Q' />
           </Card.Body>
                     ))}
           </Card>
                 )}
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>



      <Modal show={showLogs} onHide={handleCloseLogs} >
        <Modal.Header closeButton>
          <Modal.Title>     Actions Logs from Host     </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Accordion defaultActiveKey="0">
        <Card key="0">
           <Accordion.Toggle as={Card.Header} eventKey="0"> StdOut  </Accordion.Toggle>
           <Accordion.Collapse eventKey="0">

           <Card.Body style={{whiteSpace: 'pre-wrap'}}>{stdoutMessageLogs}
           </Card.Body>

           </Accordion.Collapse>
        </Card>
        <Card key="1">
           <Accordion.Toggle as={Card.Header} eventKey="1">  StdErr   </Accordion.Toggle>
           <Accordion.Collapse eventKey="1">

           <Card.Body style={{whiteSpace: 'pre-wrap'}}>{stderrMessageLogs}
           </Card.Body>

           </Accordion.Collapse>
        </Card>
          </Accordion>
             
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>






      <Modal show={show} onHide={handleClose} >
        <Modal.Header closeButton>
          <Modal.Title>     Actions List     </Modal.Title>
        </Modal.Header>
        <Modal.Body>
      <Row>
      <Col>
      </Col>
      <Col>
      <input
        type="text"
        value={searchActionItem}
        onChange={handleSearchInputChange}
        placeholder='Type to search'
      />
      </Col>
      <Col>
      </Col>
      </Row><Row>

         Please select the disired action from the list bellow: </Row>
    <Accordion defaultActiveKey="0">
                   {actionListFilterView.map((data,j) =>
        <Card key={j+1}>
           <Accordion.Toggle as={Card.Header} eventKey={j+1}>
             <Row>  {data.name} </Row> <Row> {data._owner && data._owner !=='' ? <Button variant="warning" size="sm">user</Button> : <Button variant="secondary" size="sm">system</Button>}
          {data.maybedeleted && <Button variant="primary" size="sm" onClick={(e) => deleteAction(data)}> Del </Button>  }
          {data.maybedeleted && <Button variant="primary" size="sm" onClick={(e) => handleShowCreateAction(data)}> Edit </Button>  }
          {data.maybedeleted && <Button variant="primary" size="sm" onClick={(e) => handleShowCopyAction(data)}> Copy </Button>  }
          {data.maybedeleted && <Button variant="primary" size="sm" onClick={(e) => changeMemberFromAction("current", "bind", data.id)}> Bind </Button>  }
          {data.maybedeleted && <Button variant="primary" size="sm" onClick={(e) => changeMemberFromAction("current", "unbind", data.id)}> UnBind </Button>  }
          </Row>


           </Accordion.Toggle>
           <Accordion.Collapse eventKey={j+1}>
           <Card.Body>
             <Form> <Row> {data.comment}   </Row>
              <Row>  Environment variables: </Row>
                   {data.environment_variables && data.environment_variables.map( (k, ind) => (
        <Row key={ind+1}>
        <Form.Group controlId={data.id}>
          <Form.Label>{k.name}({k.comment})</Form.Label>
          <Form.Control type="text" placeholder="enter" value={k.value} onChange={(f) => setEnv(data, j, ind, f.currentTarget.value)} />
        </Form.Group>
        </Row>
                     ))}
              <Row>Ports:</Row>
                   {data.ports && data.ports.map( (k_port, ind_port) => (
        <Row key={ind_port+1}>
        <Form.Group controlId={data.id}>
          <Form.Label>{k_port.name} {k_port.value}({k_port.comment})</Form.Label>
          <Form.Control as="select" onChange={(f) => setEnvPorts(data, j, ind_port, f.currentTarget.value)}>
                   {proxyListView.map((data_proxy,pr) =>
                             <option key={pr} value={data_proxy.host}>{data_proxy.host}({data_proxy.proxy_type}) price: x{data_proxy.price_multiplier}</option>
                       )}
          </Form.Control>          
        </Form.Group>
        </Row>
                     ))}
          <Button variant="secondary" size="sm" onClick={(e) => startAction(data)}>
            Apply
          </Button>
             </Form>
           </Card.Body>
           </Accordion.Collapse>
        </Card>
                        )}
          </Accordion>

        </Modal.Body>
        <Modal.Footer>
        <Accordion defaultActiveKey="0">
        <Card key="0">
           <Accordion.Toggle as={Card.Header} eventKey="0"> StdOut  </Accordion.Toggle>
           <Accordion.Collapse eventKey="0">
           <Card.Body style={{whiteSpace: 'pre-wrap'}}>{stdoutMessage}
           </Card.Body>
           </Accordion.Collapse>
        </Card>
        <Card key="1">
           <Accordion.Toggle as={Card.Header} eventKey="1">  StdErr   </Accordion.Toggle>
           <Accordion.Collapse eventKey="1">
           <Card.Body style={{whiteSpace: 'pre-wrap'}}>{stderrMessage}
           </Card.Body>
           </Accordion.Collapse>
        </Card>
          </Accordion>
        </Modal.Footer>
      </Modal>








      <Modal show={showCreateAction} onHide={handleCloseCreateAction} >
        <Modal.Header closeButton>
          <Modal.Title>Edit Custom Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>


            Group Members
               <ListGroup>
                       {customAction.members && customAction.members.map((group_member,iii) =>
                 <ListGroup.Item key={iii}>
                          {group_member.group_name}  {'   '}
                          <ButtonGroup>
                            {/*
                            <Button variant={group_member.btnDelColor}  onClick={(e) => delMemberFromAction(customAction.action_id, group_member.group_id)}> Del </Button>
                            <Button variant={group_member.btnBlockColor} onClick={(e) => blockMemberFromAction(customAction.action_id,group_member.group_id)}> Block </Button>
                            <Button variant={group_member.btnAdminColor} onClick={(e) => adminMemberFromAction(customAction.action_id,group_member.group_id)}> Admin </Button>
                            */}
                            <Button variant={group_member.btnDelColor} size="sm"  onClick={(e) => changeMemberFromAction(group_member.group_id, "del", customAction.id )}> Del </Button>
                            <Button variant={group_member.btnBlockColor} size="sm" onClick={(e) => changeMemberFromAction(group_member.group_id, "block", customAction.id )}> Block </Button>
                            <Button variant={group_member.btnAdminColor} size="sm" onClick={(e) => changeMemberFromAction(group_member.group_id, "admin", customAction.id )}> Admin </Button>
                          </ButtonGroup>
                 </ListGroup.Item>
                     )}
              </ListGroup>







         <Form>
        <Form.Group controlId="createCustomAction1_2">
          <Form.Label>Action Settings: {createCustomActionName}</Form.Label>
          <Form.Control type="text" placeholder="Action Name" value={customAction.name} onChange={(f) => changeCreateCustomAction("name",f.currentTarget.value)} />
          <Form.Control type="text" placeholder="Comment" value={customAction.comment} onChange={(f) => changeCreateCustomAction("comment",f.currentTarget.value)} />

          <Form.Control type="text" placeholder="conditions CPU" value={customAction.conditions.CPU} onChange={(f) => changeCreateCustomActionConditions("CPU",f.currentTarget.value)} />
          <Form.Control type="text" placeholder="conditions DISK" value={customAction.conditions.DISK} onChange={(f) => changeCreateCustomActionConditions("DISK",f.currentTarget.value)} />
          <Form.Control type="text" placeholder="conditions MEM" value={customAction.conditions.MEM} onChange={(f) => changeCreateCustomActionConditions("MEM",f.currentTarget.value)} />
            dependencies
                       {customAction.dependencies && customAction.dependencies.map((dep,dep_i) =>
                            <Button key={dep_i} variant="secondary" size="sm" onClick={(e) => delFromCustomActionArray("dependencies", dep, customAction)}> {dep} </Button>
                     )}
         
         
         <Form>
        <Form.Group controlId="addMemberToArray1_2">
          <Form.Label>Add dependence</Form.Label>
          <Form.Control type="text" placeholder="type dependence" value={dependenceForAdd} onChange={(f) => setDependenceForAdd(f.currentTarget.value)} />
          <Button variant="secondary" size="sm" onClick={(e) => { addToCustomActionArray("dependencies", dependenceForAdd); setDependenceForAdd("") }}>Add</Button>
        </Form.Group>
        </Form>
            tags
                       {customAction.tags && customAction.tags.map((tag,tag_i) =>
                            <Button key={tag_i} variant="secondary" size="sm" onClick={(e) => delFromCustomActionArray("tags", tag, customAction)}> {tag} </Button>
                     )}

         <Form>
        <Form.Group controlId="addMemberToArray2_2">
          <Form.Label>Add tag</Form.Label>
          <Form.Control type="text" placeholder="type tag" value={tagForAdd} onChange={(f) => setTagForAdd(f.currentTarget.value)} />
          <Button variant="secondary" size="sm" onClick={(e) => { addToCustomActionArray("tags", tagForAdd); setTagForAdd("") }}>Add</Button>
        </Form.Group>
        </Form>


          <Form.Control type="text" placeholder="source" value={customAction.source} onChange={(f) => changeCreateCustomAction("source",f.currentTarget.value)} />

          <Form.Control as="select" defaultValue="None" value={customAction.auth_type} onChange={(f) => changeCreateCustomAction("auth_type",f.currentTarget.value)}>
                             <option key="1" value="None">None</option>                             
                             <option key="2" value="log_pass">log_pass</option>
                             <option key="3" value="token">token</option>
                             <option key="4" value="private_key">private_key</option>
          </Form.Control>

          <Form.Control as="textarea" rows={3} placeholder="source_credentials" value={customAction.source_credentials} onChange={(f) => changeCreateCustomAction("source_credentials",f.currentTarget.value)} />
          <Form.Control type="text" placeholder="branch" value={customAction.branch} onChange={(f) => changeCreateCustomAction("branch",f.currentTarget.value)} />
          <Form.Control type="text" placeholder="source_path" value={customAction.source_path} onChange={(f) => changeCreateCustomAction("source_path",f.currentTarget.value)} />
          <Form.Control type="text" placeholder="source_run_file" value={customAction.source_run_file} onChange={(f) => changeCreateCustomAction("source_run_file",f.currentTarget.value)} />
          <Form.Control type="text" placeholder="action_timeout" value={customAction.action_timeout} onChange={(f) => changeCreateCustomAction("action_timeout",f.currentTarget.value)} />
          <Form.Control type="text" placeholder="source_timeout" value={customAction.source_timeout} onChange={(f) => changeCreateCustomAction("source_timeout",f.currentTarget.value)} />



            environment_variables
                       {customAction.environment_variables && customAction.environment_variables.map((env,env_i) =>
                            <Button key={env_i} variant="secondary" size="sm" onClick={(e) => delFromCustomActionArray("environment_variables", env.name, customAction)}> {env.name} </Button>
                     )}


         <Form>
        <Form.Group controlId="addMemberToArray3_2">
          <Form.Label>Add environment_variables</Form.Label>
          <Form.Control type="text" placeholder="type name environment_variables" value={environment_variablesForAdd.name} onChange={(f) => changeEnvironment_variablesForAdd("name", f.currentTarget.value)} />
          <Form.Control type="text" placeholder="type comment environment_variables" value={environment_variablesForAdd.comment} onChange={(f) => changeEnvironment_variablesForAdd("comment", f.currentTarget.value)} />
          <Form.Control type="text" placeholder="type value environment_variables" value={environment_variablesForAdd.value} onChange={(f) => changeEnvironment_variablesForAdd("value", f.currentTarget.value)} />
          <Button variant="secondary" size="sm" onClick={(e) => { addToCustomActionArray("environment_variables", environment_variablesForAdd); changeEnvironment_variablesForAdd("all", "") }}>Add</Button>
        </Form.Group>
        </Form>

            ports
                       {customAction.ports && customAction.ports.map((port,port_i) =>
                            <Button variant="secondary" size="sm" onClick={(e) => delFromCustomActionArrayPort("ports", port.value, port.port_type, port.vm_id, customAction)}> {port.value}:{port.port_type}({port.vm_id}) </Button>
                     )}


         <Form>
        <Form.Group controlId="addMemberToArray4_2">
          <Form.Label>Add ports</Form.Label>
          <Form.Control type="text" placeholder="name port" value={portsForAdd.name} onChange={(f) => changePortsForAdd("name", f.currentTarget.value)} />
          <Form.Control type="text" placeholder="comment port" value={portsForAdd.comment} onChange={(f) => changePortsForAdd("comment", f.currentTarget.value)} />
          <Form.Control type="text" placeholder="value port" value={portsForAdd.value} onChange={(f) => changePortsForAdd("value", f.currentTarget.value)} />
          <Form.Control as="select" defaultValue="link" value={portsForAdd.port_type} onChange={(f) => changePortsForAdd("port_type", f.currentTarget.value)}>
                             <option key="1" value="link">link</option>                             
                             <option key="2" value="port">port</option>
          </Form.Control>
          <Form.Control type="text" placeholder="vm_id port" value={portsForAdd.vm_id} onChange={(f) => changePortsForAdd("vm_id", f.currentTarget.value)} />
          <Button variant="secondary" size="sm" onClick={(e) => { addToCustomActionArray("ports", portsForAdd); changePortsForAdd("all", "") }}>Add</Button>
        </Form.Group>
        </Form>

        <Form>
        <Form.Group controlId="addMemberToArray5_2">
          <Form.Label>Type Action</Form.Label>
          <Form.Control as="select" defaultValue="private" value={customAction.action_type} onChange={(f) => changeCreateCustomAction("action_type",f.currentTarget.value)}>
                             <option key="1" value="private">private</option>
                             <option key="2" value="public">public</option>
          </Form.Control>
        </Form.Group>
        </Form>


          <Button variant="secondary" size="sm" onClick={(e) => createCustomAction()}>
            Create
          </Button>
        </Form.Group>
        </Form>
        <Alert variant='info' style={ outMessage!=='' ? {display:"block"} : {display:"none"}}>
          {outMessage}
        </Alert>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>








     </Container>  

      <Form onSubmit={callSubmit}>
        <Form.Group controlId="formAgentSearchNetwork">
        </Form.Group>

        <Alert variant='danger' style={ error!=='' ? {display:"block"} : {display:"none"}}>
          {error}
        </Alert>
        <Alert variant='info' style={ outMessage!=='' ? {display:"block"} : {display:"none"}}>
          {outMessage}
        </Alert>

        <Button variant="primary" size="sm" type="submit" block>
          Search
        </Button>
      </Form>


      <Form onSubmit={searchUnavailable}>
        <Form.Group controlId="formAgentSearchNetworkUnavailable">
        </Form.Group>
        <Button variant="primary" size="sm" type="submit" block>
          Search Unavailable
        </Button>
      </Form>





      
        {/*
        <Button variant="primary" onClick={(e) => handleShowTariffsData()}  block>
          Add tariff to user
        </Button>
        */}

    </>
  );
};
